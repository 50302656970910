<template>
  <section class="section">
    <div class="container">
      <!-- Begin main content -->
      <div class="main-content">
        <div class="container">
          <div class="row content-items">
            <div class="col-12">
              <div class="wrapp-section-title section-title-center">
                <div class="section-subtitle">CONTACT US</div>
                <h2 class="section-title">{{ title }}</h2>
              </div>
            </div>
            <div class="col-xl-4 col-md-5 content-item mb-3 mb-sm-5">
              <div class="contact-info section-bg">
                <h3>Get in touch</h3>
                <ul class="contact-list">
                  <li>
                    <i class="material-icons md-22 footer-contact-tel"
                      >smartphone</i
                    >
                    <div class="footer-contact-info">
                      <a href="tel:+441273524307" class="formingHrefTel"
                        >+44 1273 524307</a
                      >
                    </div>
                  </li>
                  <!-- <li>
                    <i class="material-icons md-22 footer-contact-tel"
                      >meeting_room</i
                    >
                    <div class="footer-contact-info">
                      <a href="tel:+441273929903" class="formingHrefTel"
                        >+44 (0) 1273 929903</a
                      >
                    </div>
                  </li> -->
                  <li>
                    <i class="material-icons md-22 footer-contact-email"
                      >email</i
                    >
                    <div class="footer-contact-info">
                      <a href="mailto:hello@objectpower.co.uk"
                        >hello@objectpower.co.uk</a
                      >
                    </div>
                  </li>
                  <li>
                    <i class="material-icons md-22">local_offer</i> VAT no.
                    GB737334526
                  </li>
                  <li>
                    <i class="material-icons md-22">verified_user</i>
                    Registered No.2958845
                  </li>
                  <li>
                    <i class="material-icons md-22">where_to_vote</i>
                    <div class="footer-contact-info">
                      <a
                        href="https://goo.gl/maps/pPQqt6xYxaWoyLWr8"
                        target="_blank"
                        >Registered Office. 100 Church Street, Brighton, East
                        Sussex BN1 1UJ, United Kingdom</a
                      >
                    </div>
                  </li>

                  <li class="pb-2 mb-0 small">
                    ObjectPower Limited is a company incorporated under the laws
                    of England and Wales.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-8 col-md-7 content-item mb-4 mb-sm-5">
              <div class="content mb-0 mb-sm-5">
                <div class="img-style">
                  <img src="/img/template/gallery-img-1.jpg" alt />
                </div>
              </div>
              <!-- <form
                action="#!"
                method="post"
                class="contact-form contact-form-padding"
              >
              
                <input type="hidden" name="form_subject" value="Contact form" />
            
                <div class="row gutters-default">
                  <div class="col-12">
                    <h3>Contact Form</h3>
                  </div>
                  <div class="col-xl-4 col-sm-6 col-12">
                    <div class="form-field">
                      <label for="contact-name" class="form-field-label"
                        >Your Name</label
                      >
                      <input
                        type="text"
                        class="form-field-input"
                        name="ContactName"
                        value
                        autocomplete="off"
                        required="required"
                        id="contact-name"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4 col-sm-6 col-12">
                    <div class="form-field">
                      <label for="contact-phone" class="form-field-label"
                        >Your Phone</label
                      >
                      <input
                        type="tel"
                        class="form-field-input mask-phone"
                        name="ContactPhone"
                        value
                        autocomplete="off"
                        required="required"
                        id="contact-phone"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4 col-12">
                    <div class="form-field">
                      <label for="contact-email" class="form-field-label"
                        >Your Email</label
                      >
                      <input
                        type="email"
                        class="form-field-input"
                        name="ContactEmail"
                        value
                        autocomplete="off"
                        required="required"
                        id="contact-email"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-field">
                      <label for="contact-message" class="form-field-label"
                        >Your Message</label
                      >
                      <textarea
                        name="ContactMessage"
                        class="form-field-input"
                        id="contact-message"
                        cols="30"
                        rows="6"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-btn">
                      <button type="submit" class="btn btn-w240 ripple">
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </form> -->
            </div>
          </div>
        </div>
      </div>
      <!-- End main content -->

      <!-- Begin map -->
      <!--    <div class="map">
        <input
          type="hidden"
          id="map_address"
          name="map_address"
          value="100 Church Street, Brighton, East Sussex BN1 1UJ, United Kingdom"
          data-marker="img/map-marker.png"
        />
        <div id="map_canvas"></div>
      </div> -->
      <!-- End map -->
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      title: "Get in touch",
    };
  },
};
</script>

<style>
</style>